<template>
  <div />
</template>

<script>
export default {
  data() {
    return {
    }
  },
  created() {
    this.social_login(this.$route.params.provider, this.$route.query.code)
  },
  methods: {
    social_login(provider, token) {
      this.$store.dispatch('auth/SocialLoginCallback', { provider, token }).then(response => {
        if (response.data.access_token) {
          this.$router.push({
            name: 'dashboard',
          })
        } else {
          this.$router.push({
            name: 'login',
          })
        }
      }).catch(() => {
        this.$router.push({
          name: 'login',
        })
      })
    },
  },
}
</script>

<style lang="scss">
  #page-login {
    .social-login-buttons {
      .bg-facebook {
        background-color: #1551b1
      }

      .bg-twitter {
        background-color: #00aaff
      }

      .bg-google {
        background-color: #4285F4
      }

      .bg-github {
        background-color: #333
      }
    }
  }
</style>
